<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Edit Item Form</span>
    </v-card-title>
      <v-container>
       <input-label>
          New Price
        </input-label>
        <v-text-field
          v-model="newPrice"
          label="new price"
          placeholder="0.3"
          :prefix = "currencySymbol"
          flat
          solo
          :rules="[rules.notNegative]"
          outlined
          type ="number"
        ></v-text-field>
            <v-btn
        class="mr-4"
        :disabled="!passValidation"
        @click="submit"
      >
        Update
      </v-btn>
      <v-btn @click="close">
        close
      </v-btn>
      </v-container>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BigNumber from 'bignumber.js';
// import formValidator from '@/lib/formValidators/formValidator';
import itemAPI from '@/api/item';

export default {
  name: 'ProfileSettingsForm',
  props: {
    id: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    newPrice: '0',
    errorMessages: {
      valueMsg: '',
    },
    rules: { // rules for user input
      notNegative: (value) => { // check if value is number
        const val = BigNumber(value);
        return (val && val.isGreaterThan(0)) || 'Invalid Value';
      },
    },
  }),
  computed: {
    ...mapGetters({ token: 'user/token' }),
    currencySymbol() {
      return this.currency.toUpperCase();
    },
    passValidation() { // check validation
      const pass = typeof this.rules.notNegative(this.newPrice) !== 'string';
      return pass;
    },
  },
  methods: {
    ...mapActions({
      alert: 'alert/callErrorMessage',
    }),
    submit() {
      itemAPI.setPrice(this.token, this.netPrice(), this.id).then((res) => {
        console.log(res);
        this.$router.go();
      }).catch((err) => {
        console.log(err);
        // add alert to system
      });
    },
    close() { // need to reset state
      this.newPrice = '0';
      this.$emit('close');
      // emit close
    },
    netPrice() {
      let calculatedVal = BigNumber(this.newPrice);
      calculatedVal = calculatedVal.multipliedBy(1000000000); // convert to eth
      return calculatedVal.toString();
    },
  },
};
</script>
